import React from "react";
import { IMaxCount } from "./BriefViewDetailsForm";
import { isNull, isUndefined } from "lodash";
import { HALF_WORK_DAY_HOURS } from "~/constants";
import moment from "moment";

export const getEntriesCounter = (item: string, maxCount: IMaxCount, selectedEntries: any, selectedDiscipline: any) => {
  const maxLimit = maxCount[item]?.limit;

  let entry: string | undefined = undefined;
  switch (item) {
    case "skills":
      entry = "superpower";
      break;
    case "sectors":
      entry = selectedDiscipline?.code === "DISCIPLINE_DESIGN" ? "tool" : "sector";
      break;
    case "platforms":
      entry = "platform";
      break;
  }

  if (!maxLimit) return null;
  if (maxLimit) {
    const count = maxLimit - selectedEntries.length;
    const isPlural = count !== 1;

    return (
      <p className="left-choose-entries">
        You can select up to <b>{count}</b> {entry}
        {isPlural ? "s" : ""}
      </p>
    );
  }
};

export const getLevels = (
  selectedDiscipline: { code: string } = { code: "" },
  selectedSpecialism: { levels: [] } = { levels: [] }
) => {
  const isCreativeDiscipline = selectedDiscipline?.code === "DISCIPLINE_CREATIVE";

  return selectedSpecialism?.levels?.filter((level: { code: string }) => {
    const disallowLevels = [];
    isCreativeDiscipline ? disallowLevels.push("LEVEL_JUNIOR") : disallowLevels.push("LEVEL_MIDSENIOR", "LEVEL_JUNIOR");
    return !disallowLevels.includes(level.code);
  });
};

export const jobUrlData = (jobUrl: string | undefined, jobUrlFormData: any) => {
  if (isUndefined(jobUrlFormData.current && !jobUrl)) {
    return false;
  }
  if (isNull(jobUrlFormData.current) && jobUrl) {
    return { job_url: null };
  }
  return jobUrlFormData.current;
};

const intersperse = (arr: any[], separator: any) =>
  arr.length === 0 ? [] : arr.slice(1).reduce((result, item, i) => result.concat([separator, item]), [arr[0]]);

export const renderBriefDates = (dates: { date: string; hours: number }[]) =>
  intersperse(
    dates.map(({ date, hours }, i) => (
      <span key={i}>
        {moment(date).format("DD/MM/YYYY")}
        {hours === HALF_WORK_DAY_HOURS ? " (.5)" : ""}
      </span>
    )),
    ", "
  );
