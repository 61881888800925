import { ITalent } from "../store/constants/talent";
import { DataTableSortOrderType } from "primereact/datatable/datatable";
import { ITalentsPaginated } from "~/store/reducers/talent";
import { SetStateAction } from "react";
import { IBooking } from "~/store/constants/bookings";
import { AxiosResponse } from "axios";
import { ILanguage } from "~/contexts/GlobalContext";

// ------------------------------------
// Constants
// ------------------------------------

export const TALENT_TYPES = [
  {
    code: "TYPE_GOTO",
    label: "Bookmarked talent",
  },
  {
    code: "TYPE_BOOKED",
    label: "Previously booked talent",
  },
  {
    code: "TYPE_INVITED",
    label: "Invited talent",
  },
  {
    code: "ALL",
    label: "All talent",
  },
];

export const TALENT_BRIEF_PREFERENCES = [
  {
    code: "DAY_RATE",
    label: "Day Rate",
  },
  {
    code: "FIXED_TERM",
    label: "Fixed Term",
  },
  {
    code: "PERMANENT",
    label: "Permanent Hiring",
  },
  {
    code: "",
    label: "All Brief Types",
  },
];

// ------------------------------------
// Interfaces
// ------------------------------------
export interface IBriefState {
  isFetching: boolean;
  isFetchingTalents: boolean;
  isMatchAdding: boolean;
  isMatchUpdating: boolean;
  isValidatingDates: boolean;
  SDSBeingLoaded: number | undefined;
  brief?: IBrief;
  briefs?: IBriefsList;
  validatedDates?: {
    duration_days: number;
    duration_weekends: number;
  };
  validatedDatesErrors: any;
  cancelBrief: (data: { briefId: number | undefined; reason_id: number; description?: string }) => Promise<void>;
  setBrief: (data: IBrief) => Promise<void>;
  deleteBrief: (briefId: number) => Promise<void>;
  createBrief: (data: IBrief) => Promise<void>;
  createClientAdminBrief: (data: IBrief) => Promise<void>;
  getBrief: (briefId: number) => Promise<void>;
  getClientBrief: (briefId: number) => Promise<void>;
  getBriefMatchesByLimit: (briefId: number, matches: number) => Promise<void>;
  listBriefs: (
    status: string,
    page: number,
    per_page: number,
    search?: string,
    sort?: { field: string; order: DataTableSortOrderType },
    other?: { [key: string]: any }
  ) => Promise<void>;
  getMatch: (matchId: number) => Promise<boolean>;
  createMatch: (talents: number | number[]) => Promise<void>;
  createClientAdminMatch: (talents: number | number[]) => Promise<void>;
  updateBrief: (data: any, shouldBriefBePublished: boolean, isBriefDraft: boolean) => Promise<void>;
  updateClientAdminBrief: (data: any, shouldBriefBePublished: boolean, isBriefDraft: boolean) => Promise<void>;
  updateDraftBrief: (data: any) => Promise<void>;
  updateClientAdminDraftBrief: (data: any) => Promise<void>;
  updateMatch: (matchToSave: any) => Promise<void>;
  updateClientAdminMatch: (matchToSave: any) => Promise<void>;
  validateBrief: (data: IBrief, type: number, IR35_TYPES: any) => Promise<unknown>;
  validateBriefDates: (data: IBriefValidate) => Promise<unknown>;
  resetBrief: () => void;
  addTalentGotoSuccess: (data: AxiosResponse) => void;
  regenerateSDS: (data: { bookingId: number; matchId: number }) => Promise<unknown>;
  listAdminAddableTalents: (data: IListAdminAddableTalentsData) => Promise<unknown>;
  trackPortfolioClick: (talentId: number, matchId: number) => void;
  trackDownloadCvClick: (talentId: number, matchId: number) => void;
  adminAddableTalents: undefined | ITalentsPaginated;
  setAdminAddableTalents: SetStateAction<any>;
  customItems: (brief: IBrief) => { name: string; items: ICustomSkill[] | ICustomSector[] | ICustomPlatform[] }[];
  isSubmitDisabled: (form: { isValid?: boolean; dirty?: boolean; isSubmitting: boolean }, initial: boolean) => boolean;
  getBriefFormValues: (additionalValues: any) => any;
  additionalBriefValues: any;
  additionalRebookValues: any;
  jobUrlFormData: React.MutableRefObject<Object | null | undefined>;
  setJobUrlFormData: (data: Object | undefined | null) => void;
  updateBooking: (
    data: { bookingId: number; matchId: number },
    payload: Partial<IBooking>,
    isNeedUpdateBookingState: boolean
  ) => Promise<unknown>;
  createBooking: (data: any, createBooking: boolean) => Promise<unknown>;
  saveOrConfirmCallback: (values: any, isAdmin: boolean) => void;
  redirectAfterBriefCreated: (brief: IBrief) => void;
  sendMessageFromBrief: (briefId: number, talents: { id: number }[], message: string) => Promise<unknown>;
  preMatchedList: IMatching[];
  getBriefPreMatchedList: (briefId: number) => Promise<unknown>;
  resetBriefPreMatchedList: () => void;
  removeFromPreMatchedList: (id: number) => void;
  checkTalentsWithSpecialismCount: (specialism_id: number, prefix: string) => Promise<boolean>;
}

export interface IBriefFilterSettings {
  briefType: { label: string; code: string };
  setBriefType: () => void;
  briefsCategories: { label: string; code: string }[];
  briefsCategory: { label: string; code: string };
  setBriefsCategory: (briefsCategoryIndex: null | { label: string; code: string }) => void;
  discipline: { label: string; code: string };
  setDiscipline: () => void;
  resetFilterBriefsSettings: (currentPath: string | null) => void;
  selectedColumns: { field: string; header: string }[];
  setSelectedColumns: () => void;
  search: string;
  setSearch: () => void;
  sort: { field: string; order: DataTableSortOrderType };
  setSort: () => void;
}
export interface IBriefValidate {
  client_id?: number;
  start_date: string | Date;
  end_date: string | Date;
  include_weekends: boolean;
  duration_id?: number;
  duration_days?: number;
  dates?: { date: string }[];
  contract_id?: number;
  job_title?: string;
  talent_rate?: number;
}

export enum BriefStatuses {
  PUBLISHED = "STATUS_PUBLISHED",
  UNPUBLISHED = "STATUS_UNPUBLISHED",
  DRAFT = "STATUS_DRAFT",
}

export interface IBrief {
  discipline_id: number;
  specialism_id: number;
  level_id: number;
  team_id: number;
  contract_id: number;
  ir35_id: number;
  production_id?: number;
  budget?: number;
  budget_type?: string;
  notes: string;
  description: null | string;
  name: string;
  location_id: null;
  author_id: number;
  client_id: number;
  client_name: string;
  created_at: Date;
  dates_flexible: boolean;
  duration_days: number;
  duration_id: number;
  duration_weekends: number;
  first_response: null | string;
  fluent_in_all: boolean;
  freeze: boolean;
  freeze_reason: null | {
    code: string;
    created_at: string;
    updated_at: string;
    description: null | string;
    name: string;
  };
  frozen_by: {
    id: number;
    name: string;
  };
  id: number;
  include_weekends: boolean;
  last_response: Date;
  legal_name: string;
  live_url: string;
  match_count?: number;
  matches: IMatching[];
  status: BriefStatuses.DRAFT | BriefStatuses.PUBLISHED | BriefStatuses.UNPUBLISHED;
  type?: string;
  brief_type?: string;
  contract_code?: string;
  discipline_code?: string;
  regions?: number[];
  goto_types?: { id: number; code: string; name: string }[];
  skills: ICustomSkill[];
  languages: ILanguage[];
  sectors: ICustomSector[];
  custom_skills: ICustomSkill[];
  custom_sectors: ICustomSector[];
  custom_platforms: ICustomPlatform[];
  platforms?: ICustomSkill[];
  start_date: string;
  end_date: string;
  updated_at: string;
  ir35: {
    freelancer_arrange_space: 1 | 0;
    freelancer_defines_location: 1 | 0;
    freelancer_flexibility: string;
    freelancer_type: string;
    payment_company_name: string;
  };
  umbrella_companies?: IUmbrella[];
  dates?: { date: string; hours: number }[];
  job_title?: string;
  rate_min?: number;
  rate_max?: number;
  work_description?: string;
  work_setting?: string;
  right_requirements?: string;
  permanent_roles?: boolean;
  job_url?: string;
  job_url_name?: string;
  role_involves?: string;
  country_of_residence?: string;
  residence_city?: string;
  company_benefits?: string;
  excluded_talents: number[] | { id: number; name: string }[];
}
export type IUmbrella = {
  id: number;
  company_name: string;
};
export type IPaymentCompany = {
  id?: number;
  name: string;
};
export type ICustomItem = {
  id: number;
  name: string;
  order_id?: number;
};
export type ICustomSkill = {
  id: number;
  name: string;
};
export type ICustomPlatform = {
  id: number;
  name: string;
};
export type ICustomSector = {
  id: number;
  name: string;
};
export interface IBriefsList {
  [k: string]: any;
  closed: IBrief[];
  fulfilled: IBrief[];
  opened: IBrief[];
  resourced: IBrief[];
}

export interface IMatching {
  id: number;
  bookings: IBooking[];
  brief_id: number;
  status: string;
  brief_accepted: 0 | 1;
  brief_declined: 0 | 1;
  interview_requested: 0 | 1;
  interview_medium: string | null;
  interview_date: Date | string | null;
  booking_requested: 0 | 1;
  client_feedback: string | null;
  talent: ITalent;
  talent_feedback: string | null;
  talent_booked: 0 | 1;
  talent_rejected: 0 | 1;
  talent_rate: number | null;
  masked_talent_rate: string | null;
  talent_location: string | null;
  talent_substitution: string | null;
  wildcard: 0 | 1;
  display_order: number;
  system_matched: 0 | 1;
  created_at: string;
  updated_at: string;
  score_total: number;
  start_date?: string;
  end_date?: string;
  [key: string]: any;
  reason_id?: number;
  talent_type: string | null;
  notes?: string;
}

export interface IBriefClientAdminData {
  id: number;
  description?: string | null;
  start_date?: string;
  end_date?: string;
  sds_ready?: boolean;
  include_weekends?: boolean;
  duration_days?: number;
  dates?: { date: string }[];
  job_url?: string;
}

export interface IListAdminAddableTalentsData {
  briefId: number;
  page: number;
  search?: string;
  sort?: { field: string; order: DataTableSortOrderType };
}
