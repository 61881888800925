import { isNull, isUndefined } from "lodash";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { MultiSelect } from "primereact/multiselect";
import { Paginator, PaginatorPageState } from "primereact/paginator";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useDebounce from "~/components/common/debounce";
import * as permissionType from "~/constants";
import { useBriefContext } from "~/contexts/BriefContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { checkBriefForDraft } from "~/routes/Brief/helper";
import actions from "~/store/actions";
import toasts from "~/store/actions/toasts";
import { prepareCustomColumns, transformArrayToMap } from "~/utils";
import { BriefStatuses, IBrief } from "../../../interfaces/brief";
import { IState } from "../../../store/reducers/index";
import BriefTypesDropdown from "../BriefTypesDropdown/BriefTypesDropdown";
import DisciplinesDropdown, { ALL_DISCIPLINES } from "../DisciplinesDropdown/DisciplinesDropdown";
import "./BriefsTabs.scss";
import getColumns, { IColumn } from "./columns";

interface Props {
  className: string;
  search: string;
  clientId?: string;
}

export default function BriefsTabs(props: Props) {
  const {
    listBriefs,
    isFetching: loading,
    briefs,
    briefsCategories,
    briefsCategory,
    setBriefsCategory,
    discipline,
    setDiscipline,
    selectedColumns,
    setSelectedColumns,
    briefType,
    setBriefType,
    sort,
    setSort,
    resetFilterBriefsSettings,
    deleteBrief,
  } = useBriefContext();
  const {
    global: { globalOptions },
  } = useGlobalContext();
  const { userAccess } = usePermissionContext();
  const isAdmin = userAccess(permissionType.accessAdmin);
  const { search } = props;
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const CONTRACT_TYPES = transformArrayToMap(globalOptions?.contract_types);
  const DISCIPLINES = transformArrayToMap(globalOptions?.disciplines);
  const locationState = useSelector((state: IState) => state.location.state) as { briefsCategoryIndex: number };

  const sortFunc = sortBackend();
  const debouncedSearch = useDebounce(search, 1000);

  const menuRefs = useRef([]);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      props.clientId && resetFilterBriefsSettings("/clients");
    };
  }, []);

  useEffect(() => {
    isNull(briefsCategory) && setBriefsCategory(briefsCategories[locationState?.briefsCategoryIndex ?? 0]);
    window.history.replaceState({}, document.title);
  }, [locationState?.briefsCategoryIndex]);

  useEffect(() => {
    if (!isUndefined(debouncedSearch) && !isNull(briefsCategory)) {
      getBriefs({ page: 1 });
    }
  }, [briefsCategory, debouncedSearch, sort, discipline, briefType]);

  useEffect(() => {
    const orderedSelectedColumns = columnsOptions.filter((col) =>
      selectedColumns.some((sCol: { field: string; header: string }) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  }, [briefsCategory]);

  useEffect(() => {
    getBriefs({ page });
  }, [perPage, page]);

  const columns = !isNull(briefsCategory) && getColumns(isAdmin, briefsCategory.code, { DISCIPLINES, CONTRACT_TYPES });

  const columnsOptions = prepareCustomColumns(columns);
  const customColumns = selectedColumns.map((sCol: IColumn) => {
    const { field, header, props } = columns.find((col) => col.field === sCol.field) as IColumn;
    return <Column key={field} field={field} header={header} {...props} />;
  });

  function sortBackend() {
    var sorting = false;
    function askSort(e: { sortField: any; sortOrder: any }) {
      if (!sorting) {
        sorting = true;
        setSort({
          field: e.sortField,
          order: e.sortOrder,
        });
      }
      return 0;
    }
    return askSort;
  }
  function changeBriefsCategory(e: DropdownChangeParams) {
    if (!isNull(e.value)) {
      setBriefsCategory(e.value);
      setDiscipline(ALL_DISCIPLINES);
    }
  }

  function renderColumnName(rowData: IBrief) {
    let rebooked = null;
    const link = checkBriefForDraft(rowData) ? `/briefs/draft/${rowData?.id}` : `/briefs/${rowData?.id}`;

    switch (rowData.type) {
      case "REBOOK":
        rebooked = <span className="pi pi-user rebook mr-2" title="Direct book" />;
        break;
      case "AVAILABILITY":
        rebooked = <span className="pi pi-users mr-2" />;
        break;
      default:
    }
    return (
      <strong>
        {rebooked}
        <Link to={link}>{rowData?.name}</Link>
      </strong>
    );
  }

  function renderColumnClient(rowData: IBrief) {
    return <Link to={`/clients/${rowData?.client_id}`}>{rowData?.client_name}</Link>;
  }

  function renderColumnStatus(rowData: IBrief) {
    let colorClass = "";
    let labelStatus = rowData.status.split("_")[1] || "";

    switch (rowData.status) {
      case BriefStatuses.PUBLISHED:
        colorClass = "requested";
        break;
      case BriefStatuses.UNPUBLISHED:
        colorClass = "declined";
        break;
      case BriefStatuses.DRAFT:
        colorClass = "pending";
        break;
      default:
        colorClass = "pending";
    }

    return (
      <span className={`matching-status ${colorClass}`} title={rowData.status}>
        {labelStatus}
      </span>
    );
  }

  function onPaginatorChange(e: PaginatorPageState) {
    setPerPage(e.rows);
    setPage(e.page + 1);
  }

  function getBriefs({ page = 1 }: { page: number }) {
    !isNull(briefsCategory) &&
      listBriefs(briefsCategory.code, page, perPage, search, sort, {
        ...(props.clientId && { client_id: props.clientId }),
        ...(discipline.code && { discipline: discipline.code }),
        ...(briefType.code && { contract_code: briefType.code }),
      });
  }

  function renderBriefActions(rowData: IBrief, { rowIndex }: { rowIndex: number }) {
    return rowData.status === BriefStatuses.DRAFT ? (
      <div className="brief-actions">
        <Button icon="pi pi-ellipsis-h" onClick={(event) => menuRefs.current[rowIndex].toggle(event)} />
        <Menu
          model={[
            {
              label: "Delete Draft",
              command: () => {
                dispatch(
                  toasts.setPopup({
                    content: (
                      <>
                        Are you sure you want to cancel this draft brief? Once cancelled it will be deleted permanently
                        from your drafts.
                      </>
                    ),
                    buttons: [
                      {
                        text: "Dismiss",
                        callback: () => dispatch(actions.modal.closeModal()),
                      },
                      {
                        text: "Delete Draft",
                        callback: () => deleteBrief(rowData.id),
                      },
                    ],
                  })
                );
              },
            },
          ]}
          popup
          ref={(el) => (menuRefs.current[rowIndex] = el)}
          className="brief-action-buttons"
        />
      </div>
    ) : null;
  }

  function renderMultiSelect() {
    return (
      <MultiSelect
        id="columns"
        className="columnsSelect"
        value={selectedColumns}
        optionLabel="header"
        options={columnsOptions}
        onChange={({ value: selectedColumns }) => {
          const orderedSelectedColumns = columnsOptions.filter((col) =>
            selectedColumns.some((sCol: { field: string; header: string }) => sCol.field === col.field)
          );
          setSelectedColumns(orderedSelectedColumns);
        }}
        dropdownIcon="pi pi-plus"
      />
    );
  }

  return (
    <div className={props.className}>
      <div className="briefTabsTools">
        <div>
          <Dropdown
            className="filteringSelector"
            value={briefsCategory}
            options={briefsCategories}
            onChange={changeBriefsCategory}
            optionLabel="label"
            scrollHeight="300"
          />
          <DisciplinesDropdown discipline={discipline} setDiscipline={setDiscipline} />
          <BriefTypesDropdown briefType={briefType} setBriefType={setBriefType} />
        </div>
      </div>
      <div className="flex">
        <div className="more-columns-mobile">{renderMultiSelect()}</div>
      </div>
      <DataTable
        autoLayout={true}
        emptyMessage="No briefs found"
        globalFilter={search}
        loading={loading}
        lazy={true}
        value={(briefs?.data ?? []) as any[]}
        sortOrder={sort.order}
        sortField={sort.field}
        onSort={sortFunc}
      >
        <Column body={renderColumnName} field="name" header="Name" sortable={true} />
        <Column body={renderColumnStatus} field="status" header="Status" sortable={true} />
        {isAdmin && <Column body={renderColumnClient} field="client_name" header="Client" sortable={true} />}
        {customColumns}
        <Column body={renderBriefActions} />
        <Column field="custom_columns" header={renderMultiSelect} className="more-columns" />
      </DataTable>
      {briefs?.total > briefs?.per_page && (
        <Paginator
          rows={perPage}
          totalRecords={briefs?.total}
          first={(briefs?.current_page - 1) * briefs?.per_page}
          rowsPerPageOptions={[20, 50, 100, 200]}
          onPageChange={onPaginatorChange}
        ></Paginator>
      )}
    </div>
  );
}
