import React from "react";
import moment from "moment";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import toasts from "~/store/actions/toasts";
import BriefDays from "../../BriefDays";
import "./CalendarFooterTemplate.scss";

interface IFooterTemplate {
  dates: { date: Date; hours?: number }[] | undefined;
  resetDates: () => void;
}

export default function CalendarFooterTemplate({ dates, resetDates }: IFooterTemplate) {
  const dispatch = useDispatch();

  const showPopup = () => {
    dispatch(
      toasts.setPopup({
        content: (
          <div className="selected-brief-dates">
            <p>Selected dates:</p>
            <BriefDays dates={dates} />
          </div>
        ),
        buttons: [{ text: "Got It" }],
      })
    );
  };
  return (
    <div className="selected-dates">
      <Button disabled={!dates.length} label="Clear Dates" onClick={() => resetDates()} />
      {!!dates.length && (
        <div className="dates">
          <span>Selected dates:</span>
          <div>
            <span>
              {moment(dates[0].date).format("DD MMM, YYYY")}
              <p className="more" onClick={showPopup}>
                ...
              </p>
              {moment(dates[dates.length - 1].date).format("DD MMM, YYYY")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
