import React, { useMemo, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import * as permissionType from "~/constants";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useTalentContext } from "~/contexts/TalentContext";
import { useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import { ITalent } from "~/store/constants/talent";
import TalentTypeIcon from "~/components/common/ApCard/shared/TalentTypeIcon";
import { Link } from "react-router-dom";
import TalentPreferences from "~/routes/Talent/TalentPreferences";
import getColumns, { IColumn } from "~/routes/Talents/TalentsTable/columns";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { MultiSelect } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import { prepareCustomColumns } from "~/utils";
import { ViewModes } from "~/components/common/ViewToggler/ViewToggler";
import cn from "classnames";

interface ITalentsTable {
  renderDirectBookButton: (preSelectedTalents: ITalent[], label?: string) => void;
  renderTalentActions: (rowData: ITalent, { rowIndex }: { rowIndex: number }) => void;
  selectedItems: ITalent[] | null;
  setSelectedItems: (talents: ITalent[]) => void;
  isTalentNetworkTab?: boolean;
}

export default function TalentsTable(props: ITalentsTable) {
  const { renderDirectBookButton, renderTalentActions, selectedItems, setSelectedItems, isTalentNetworkTab } =
    props || {};

  const { userAccess } = usePermissionContext();
  const {
    global: { globalOptions },
  } = useGlobalContext();
  const { sort, search, setSort, selectedColumns, setSelectedColumns, setScrollToId } = useTalentContext();

  const invalidRef = useRef<(HTMLElement | null)[]>([]);

  const isAdmin = useMemo(() => userAccess(permissionType.accessAdmin), []);
  const isClientAdmin = useMemo(() => userAccess(permissionType.accessClientAdmin), []);

  const loading = useSelector((state: IState) => state.talent.isFetching);
  const talents = useSelector((state: IState) => state.talent.talentsPaginated);

  const columns = getColumns(isAdmin, globalOptions, isTalentNetworkTab);
  const columnsOptions = prepareCustomColumns(columns);
  const customColumns = selectedColumns.map((sCol: IColumn) => {
    const { field, header, props } = columns.find((col) => col.field === sCol.field) as IColumn;
    return <Column key={field} field={field} header={header} {...props} />;
  });

  const sortBackend = () => {
    var sorting = false;
    function askSort(e: { sortField: any; sortOrder: any }) {
      if (!sorting) {
        sorting = true;
        setSort({
          field: e.sortField,
          order: e.sortOrder,
        });
      }
      return 0;
    }
    return askSort;
  };
  const sortFunc = sortBackend();

  const renderColumnName = (rowData: ITalent, { rowIndex }: { rowIndex: number }) => (
    <strong id={isClientAdmin ? rowData.id : undefined} className="talent-column-name">
      <TalentTypeIcon
        type={
          rowData.type ||
          (isAdmin && rowData.is_invited && "TYPE_INVITED") ||
          (isAdmin && !rowData.is_invited && "TYPE_GENIE")
        }
        isBlocked={rowData.is_blocked}
        viewMode={ViewModes.TABLE}
      />
      <Tooltip position="top" target={invalidRef.current[rowIndex]} />
      {rowData?.is_invalid && (
        <i
          ref={(el) => (invalidRef.current[rowIndex] = el)}
          className="pi pi-exclamation-circle invalid-talent"
          style={{ marginRight: "10px" }}
          data-pr-tooltip="Invalid talent"
        />
      )}
      <Link
        to={`/talents/${rowData.id}`}
        className={cn(rowData?.is_invalid && "invalid-talent")}
        onClick={isClientAdmin ? () => setScrollToId(rowData.id) : undefined}
      >
        {rowData.name}
      </Link>
      {isAdmin && <TalentPreferences rowData={rowData} />}
    </strong>
  );

  const renderColumnPublished = (rowData: ITalent) => {
    switch (true) {
      case rowData.freeze:
        return <span className="published-status draft">Frozen</span>;
      case rowData.published && rowData.matchable === "100%":
        return <span className="published-status matchable">Matchable</span>;
      case rowData.published:
        return <span className="published-status published">Published</span>;
      default:
        return <span className="published-status draft">Draft</span>;
    }
  };

  function rowClass(rowData: any) {
    return { "row-blocked": rowData.is_blocked };
  }

  function renderMultiSelect() {
    return (
      <MultiSelect
        id="columns"
        className="columnsSelect"
        value={selectedColumns}
        optionLabel="header"
        options={columnsOptions}
        onChange={({ value: selectedColumns }) => {
          const orderedSelectedColumns = columnsOptions.filter((col) =>
            selectedColumns.some((sCol: { field: string; header: string }) => sCol.field === col.field)
          );
          setSelectedColumns(orderedSelectedColumns);
        }}
        dropdownIcon="pi pi-plus"
      />
    );
  }

  return (
    <>
      <div className="flex">
        {!!renderDirectBookButton && renderDirectBookButton(selectedItems || [])}
        {isAdmin && <div className="more-columns-mobile">{renderMultiSelect()}</div>}
      </div>
      <DataTable
        className="TalentsView__table"
        autoLayout={true}
        rowClassName={rowClass}
        emptyMessage="No talents found"
        globalFilter={search}
        loading={loading}
        lazy={true}
        value={talents?.data || []}
        selection={selectedItems}
        showSelectionElement={(rowData) =>
          !rowData.is_blocked && rowData?.direct_book_ready !== false && rowData?.is_available !== false
        }
        onSelectionChange={(e) => setSelectedItems(e.value)}
        sortOrder={sort.order}
        sortField={sort.field}
        onSort={sortFunc}
        isDataSelectable={({ data }) => !data?.is_blocked && data?.direct_book_ready && data?.is_available}
      >
        {!isAdmin && !isTalentNetworkTab && <Column selectionMode="multiple" style={{ width: "3em" }} />}
        <Column body={renderColumnName} field="name" header="Name" sortable={true} />
        {(!isAdmin || isTalentNetworkTab) && (
          <Column
            className="bookings-count-column-name"
            field="bookings_count"
            style={{ textAlign: "center" }}
            header="Number of bookings"
            sortable={true}
          />
        )}
        {isAdmin && !isTalentNetworkTab && (
          <Column body={renderColumnPublished} field="published" header="Status" sortable={true} />
        )}
        <Column field="specialism_name" header="Specialism" sortable={true} />
        <Column field="level_name" header="Level" sortable={true} />
        {customColumns}
        {isClientAdmin && <Column body={renderTalentActions} className="actions" />}
        {isAdmin && <Column field="custom_columns" header={renderMultiSelect} className="more-columns" />}
      </DataTable>
    </>
  );
}
