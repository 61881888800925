import { ITag } from "~/interfaces/common";
import { DataTableSortMeta, DataTableSortOrderType } from "primereact/datatable";
import { AnyAction } from "redux";
import * as Talent from "../constants/talent";
import { PaginatorPageState } from "primereact/paginator";

// ------------------------------------
// Interface
// ------------------------------------
export interface ITalentsPaginated {
  data: Talent.ITalent[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
  };
}

export interface ITalentState {
  isFetching: boolean;
  selectedTalents: number[];
  talents?: Talent.ITalent[];
  talent?: Talent.ITalent;
  talentsPaginated?: ITalentsPaginated;
  rebook?: any[];
  isFetchingTags?: boolean;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: ITalentState = {
  isFetching: false,
  selectedTalents: [],
  talents: undefined,
  talent: undefined,
  talentsPaginated: undefined,
  rebook: undefined,
};

type ISelectOption = { id?: number; code?: string; label: string };
export interface ITalentFilterSettings {
  discipline: { label: string; code: string };
  defaultTalentType: { label: string; code: string };
  defaultTalentBriefPreference: { label: string; code: string };
  setDiscipline: (discipline: { label: string; code: string }) => void;
  resetFilterTalentSettings: (currentPath: string | null) => void;
  onPaginatorChange: (e: PaginatorPageState) => void;
  talentBriefPreference: { label: string; code: string };
  setTalentBriefPreference: (value: any) => void;
  selectedColumns: { field: string; header: string }[];
  setSelectedColumns: (orderedSelectedColumns: { field: string; header: string }[]) => void;
  talentType: ISelectOption;
  setTalentType: (talentType: ISelectOption) => void;
  specialisms: ISelectOption[];
  setSpecialisms: (specialisms: ISelectOption[] | never[]) => void;
  levels: ISelectOption[];
  setLevels: (levels: ISelectOption[] | never[]) => void;
  skills: ISelectOption[];
  setSkills: (skills: ISelectOption[] | never[]) => void;
  sectors: ISelectOption[];
  setSectors: (sectors: ISelectOption[] | never[]) => void;
  tagsFilter: number[];
  setTagsFilter: (value: number[]) => void;
  search: string;
  setSearch: (str: string) => void;
  sort: { field: string; order: DataTableSortOrderType };
  setSort: (opts: DataTableSortMeta) => void;
  tagsOperator: string;
  setTagsOperator: (value: string) => void;
  scrollToId: number | null;
  setScrollToId: (value: number | null) => void;
  page: number;
  perPage: number;
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): ITalentState => {
  switch (action.type) {
    case Talent.TALENT_SAVE_REBOOK_REQUEST:
    case Talent.TALENTS_LIST_REQUEST:
    case Talent.TALENTS_NOTES_UPDATE:
    case Talent.TALENTS_LIST_PAGINATED_REQUEST:
    case Talent.TALENT_FILE_SAVE_REQUEST:
    case Talent.TALENT_GET_REQUEST:
    case Talent.TALENT_GET_REBOOK_REQUEST:
    case Talent.TALENT_CREATE_REQUEST:
    case Talent.TALENT_UPDATE_REQUEST:
    case Talent.CLIENT_ADMIN_TALENT_SAVE_REBOOK_REQUEST:
    case Talent.TALENTS_LOCK_STATUS_UPDATE:
      return {
        ...state,
        isFetching: true,
      };
    case Talent.TALENTS_LIST_FAILURE:
    case Talent.TALENTS_LIST_PAGINATED_FAILURE:
    case Talent.TALENT_GET_FAILURE:
    case Talent.TALENT_GET_REBOOK_FAILURE:
    case Talent.TALENT_CREATE_FAILURE:
    case Talent.TALENT_UPDATE_FAILURE:
    case Talent.TALENT_FILE_SAVE_FAILURE:
    case Talent.CLIENT_ADMIN_TALENT_SAVE_REBOOK_FAILURE:
    case Talent.CLIENT_ADMIN_TALENT_SAVE_REBOOK_SUCCESS:
    case Talent.TALENTS_NOTES_FAILURE:
    case Talent.TALENTS_LOCK_STATUS_FAILURE:
    case Talent.TALENT_SAVE_REBOOK_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case Talent.TALENTS_LIST_SUCCESS:
    case Talent.TALENT_SAVE_REBOOK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        talents: action.payload,
      };
    case Talent.TALENTS_LIST_PAGINATED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        talentsPaginated: action.payload,
      };
    case Talent.UPDATE_TALENT_PAGINATED_NOTES:
      return {
        ...state,
        isFetching: false,
        talentsPaginated: {
          ...state.talentsPaginated,
          data: [
            ...state.talentsPaginated.data.map((talent) => {
              if (talent.id === action.payload.id) {
                return {
                  ...talent,
                  note: action.payload.note,
                };
              } else {
                return talent;
              }
            }),
          ],
        },
      };
    case Talent.TALENT_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case Talent.TALENT_GET_SUCCESS:
      let talent = { ...action.payload };
      const { blocked_clients, boosted_clients } = talent;

      const formatArr = (arr: any) => arr.map((el: any) => el?.id);

      if (blocked_clients?.length) talent.blocked_clients = formatArr(blocked_clients);
      if (boosted_clients?.length) talent.boosted_clients = formatArr(boosted_clients);
      return {
        ...state,
        talent,
        isFetching: false,
      };
    case Talent.SET_TALENT:
      return {
        ...state,
        talent: action.payload,
      };
    case Talent.TALENT_GET_REBOOK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        rebook: action.payload,
      };
    case Talent.TALENT_REBOOK_RESET:
      return {
        ...state,
        rebook: undefined,
      };
    case Talent.TALENT_FILE_SAVE_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          work_profile: action.payload.work_profile,
        },
        isFetching: false,
      };
    case Talent.TALENTS_ADD_GOTO_REQUEST:
    case Talent.TALENTS_REMOVE_GOTO_REQUEST:
      return {
        ...state,
        selectedTalents: action.payload,
      };
    case Talent.TALENTS_ADD_GOTO_SUCCESS:
      return {
        ...state,
        talentsPaginated: {
          ...state.talentsPaginated,
          data: [
            ...state.talentsPaginated.data.map((talent) => {
              if (state.selectedTalents.includes(talent.id)) {
                const updatedTalent = action.payload.find((item) => item.id === talent.id);
                updatedTalent.bookings_count = talent.bookings_count;
                return updatedTalent ? updatedTalent : talent;
              } else return talent;
            }),
          ],
          selectedTalents: [],
        },
      };
    case Talent.TALENTS_REMOVE_GOTO_SUCCESS:
      return {
        ...state,
        talentsPaginated: {
          ...state.talentsPaginated,
          data: [
            ...state.talentsPaginated.data.map((talent) => {
              if (state.selectedTalents.includes(talent.id)) {
                talent.type = "TYPE_BOOKED";
              }
              return talent;
            }),
          ],
          selectedTalents: [],
        },
      };
    case Talent.TALENTS_NOTES_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          note: {
            ...state.talent.note,
            ...action.payload,
          },
        },
        isFetching: false,
      };
    case Talent.TALENTS_LOCK_STATUS_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          is_blocked: action.payload.is_blocked,
        },
        talentsPaginated: {
          ...state.talentsPaginated,
          data: [
            ...state.talentsPaginated.data.map((talent) => {
              if (talent.id === action.payload.id) {
                action.payload.bookings_count = talent.bookings_count;
                return action.payload;
              }

              return talent;
            }),
          ],
        },
        isFetching: false,
      };
    case Talent.TALENT_UPDATE_USER_WORK_PROFILE_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          work_profile: { ...action.payload.work_profile },
          country: action.payload.work_profile.country,
          company_types: action.payload.work_profile.company_types,
        },
        isFetching: false,
      };
    case Talent.TALENT_UPDATE_WORK_PROFILE_SUCCESS:
      return {
        ...state,
        talent: {
          ...state.talent,
          work_profile: { ...action.payload },
          country: action.payload.country,
          company_types: action.payload.company_types,
          platforms: action.payload.platforms,
        },
        isFetching: false,
      };
    case Talent.USERS_UPDATE_TALENT_CV:
      return {
        ...state,
        talent: {
          ...state.talent,
          work_profile: {
            ...state?.talent?.work_profile,
            cv_url: action.payload.cv_url,
          },
        },
      };
    case Talent.ADD_TALENT_TAG_REQUEST:
    case Talent.REMOVE_TALENT_TAG_REQUEST:
      return {
        ...state,
        isFetchingTags: true,
      };
    case Talent.REMOVE_TALENT_TAG_FAILURE:
    case Talent.ADD_TALENT_TAG_FAILURE:
      return {
        ...state,
        isFetchingTags: false,
      };
    case Talent.REMOVE_TALENT_TAG_SUCCESS:
      return {
        ...state,
        isFetchingTags: false,
        talent: {
          ...state.talent,
          tags: state.talent?.tags?.filter((tag: ITag) => tag.id !== action.payload.tagId),
        },
        talentsPaginated: {
          ...state.talentsPaginated,
          data: [
            ...(state.talentsPaginated?.data.map((talent) => {
              if (talent.id === action.payload.id) {
                return { ...talent, tags: talent.tags?.filter((tag: ITag) => tag.id !== action.payload.tagId) };
              } else {
                return talent;
              }
            }) || []),
          ],
        },
      };
    case Talent.ADD_TALENT_TAG_SUCCESS:
      return {
        ...state,
        isFetchingTags: false,
        talent: {
          ...state.talent,
          tags: [...(state.talent?.tags || []), action.payload.tag],
        },
        talentsPaginated: {
          ...state.talentsPaginated,
          data: [
            ...(state.talentsPaginated?.data.map((talent) => {
              if (talent.id === action.payload.id) {
                return { ...talent, tags: [...talent.tags, action.payload.tag] };
              } else {
                return talent;
              }
            }) || []),
          ],
        },
      };
    case Talent.UPDATE_TALENTS_PAGINATED_TAGS: {
      return {
        ...state,
        talent: {
          ...state.talent,
          tags:
            state.talent?.tags?.map((tag) => {
              if (tag.id === action.payload.id) {
                return {
                  ...tag,
                  ...action.payload,
                };
              } else return tag;
            }) || [],
        },
        talentsPaginated: {
          ...state.talentsPaginated,
          data: [
            ...(state.talentsPaginated?.data.map((talent) => {
              if (talent.tags?.length) {
                const newTags = talent.tags.map((tag) => {
                  if (tag.id === action.payload.id) {
                    return {
                      ...tag,
                      ...action.payload,
                    };
                  } else return tag;
                });
                return { ...talent, tags: newTags };
              } else {
                return talent;
              }
            }) || []),
          ],
        },
      };
    }
    case Talent.DELETE_TALENTS_PAGINATED_TAGS: {
      return {
        ...state,
        talent: {
          ...state.talent,
          tags: state.talent?.tags?.filter((tag) => tag.id !== action.payload) || [],
        },
        talentsPaginated: {
          ...state.talentsPaginated,
          data: [
            ...state.talentsPaginated.data.map((talent) => {
              if (talent.tags?.length && talent.tags.some((tag) => tag.id === action.payload)) {
                const newTags = talent.tags.filter((tag) => tag.id !== action.payload);
                return { ...talent, tags: newTags };
              } else {
                return talent;
              }
            }),
          ],
        },
      };
    }
    default:
      return state;
  }
};
