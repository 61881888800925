import { Form, Formik, FormikProps, FormikValues } from "formik";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BriefDatesDaysCount from "~/components/BriefDatesDaysCount/BriefDatesDaysCount";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import PopupIcon from "~/components/Toaster/PopupIcon";
import * as permissionType from "~/constants";
import { useBriefContext } from "~/contexts/BriefContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import CalendarBlock, { ENTITY_TYPE } from "~/forms/BriefViewDetailsForm/Calendar/CalendarBlock/CalendarBlock";
import DurationTypesPopup from "~/forms/BriefViewDetailsForm/DurationTypesPopup/DurationTypesPopup";
import { BookingDateConfirmSchema } from "~/schemas/BookingDateConfirmSchema";
import actions from "~/store/actions";
import toasts from "~/store/actions/toasts";
import { calculateDates, getDiffBetweenDays } from "~/utils";
import { IState } from "../../store/reducers/index";
import "./BookingExtendModal.scss";

const DEFAULT_DURATION_DAYS = 1;

export default function BookingExtendModal() {
  const dispatch = useDispatch();
  const { brief } = useBriefContext();
  const { userAccess, checkIsAdmin } = usePermissionContext();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const [step, setStep] = useState<number>(1);
  const isAdmin = useMemo(checkIsAdmin, []);

  const {
    id,
    brief_id,
    match_id,
    start_date,
    end_date,
    include_weekends,
    duration_days,
    duration_weekends,
    duration_id,
    talent_rate,
    contract_id,
    talent,
    talent_id,
    client_id,
    author,
    author_id,
    status,
  } = modalProps || {};

  const {
    global: { globalOptions, optionsNormalization },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const [durationDays, setDurationDays] = useState(duration_days + duration_weekends || 1);
  const [maxDurationDays, setMaxDurationDays] = useState(null as any);
  const isExactWorkingDates = duration_id === DURATION_ID?.EXACT;

  const [dates, setDates] = useState(calculateDates.currentBrief(modalProps, isExactWorkingDates));

  const durationTypes = globalOptions?.duration_types;
  const durationTypeOptions = useMemo(
    () =>
      durationTypes
        ?.filter(({ is_enabled }: { is_enabled: boolean }) => is_enabled)
        ?.map(({ id: code, name, description }: { id: string; name: string; description: string }) => ({
          code,
          name,
          description,
        })),
    [durationTypes]
  );

  const changeDurationDays = (dates: Date[], day: number = DEFAULT_DURATION_DAYS) => {
    const endDate = dates[1] ? moment(dates[1]) : moment(dates[0]);
    const startDate = moment(dates[0]);
    const days = getDiffBetweenDays({ startDate, endDate });
    setMaxDurationDays(days + 1 || null);
    setDurationDays(day);
  };
  useEffect(() => {
    changeDurationDays([start_date, end_date], duration_days + duration_weekends);
  }, []);

  function onSubmit(data: any) {
    dispatch(
      toasts.setPopup({
        content: (
          <BriefDatesDaysCount
            data={data}
            callback={(dates) => {
              data.duration_days = dates.duration_days;
              data.duration_weekends = dates.duration_weekends;
            }}
          />
        ),
        buttons: [
          { text: "Go back" },
          {
            callback: () =>
              modalProps.onSubmit &&
              modalProps.onSubmit(
                {
                  related_booking_id: id,
                  brief_id,
                  match_id,
                  start_date: moment(data.start_date).format("YYYY-MM-DD"),
                  end_date: moment(data.end_date ?? data.start_date).format("YYYY-MM-DD"),
                  include_weekends: data.include_weekends,
                  duration_id: data.duration_id,
                  duration_days: data.duration_days,
                  duration_weekends: data.duration_weekends,
                  dates: calculateDates.formatDates(data.dates),
                  talent_rate: data.talent_rate,
                  status: "EXTENSION_REQUESTED",
                  type: "EXTENSION",
                },
                onClose
              ),
            text: "Save & Notify",
          },
        ],
      })
    );
  }

  const onClose = () => dispatch(actions.modal.closeModal());

  const changeDurationTypes = (value: number, formik: FormikProps<FormikValues>) => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
    });
    formik.setFieldValue("duration_id", value);
    formik.setFieldValue("duration_days", DEFAULT_DURATION_DAYS);
    setDurationDays(DEFAULT_DURATION_DAYS);
    setMaxDurationDays(null);
  };

  return step === 1 ? (
    <Dialog
      className="ExtendBookingDatesModal"
      header="Please Note"
      footer={
        <footer>
          <Link
            to={{
              pathname: "/briefs/direct_book",
              state: {
                preSelectedTalents: [{ id: isAdmin ? talent_id : talent?.id }],
                ...(isAdmin && {
                  preSelectedClient: { client: client_id, clientUser: author?.id || author_id },
                }),
              },
            }}
          >
            <Button label="Direct Book" className="p-button-rounded" onClick={onClose} />
          </Link>
          <Button label="Extend Booking" type="submit" onClick={() => setStep(2)} />
        </footer>
      }
      visible={true}
      modal={true}
      onHide={onClose}
    >
      <p>You can only extend a booking when it is a continuation of the previous booking's scope of work.</p>
      <p>
        If the scope of work has changed, or you are booking the talent for a separate project you must create a new
        brief.
      </p>
    </Dialog>
  ) : (
    <Formik
      initialValues={{
        ...(userAccess(permissionType.accessAdmin) && { client_id: brief?.client_id }),
        include_weekends: include_weekends || false,
        duration_id: duration_id,
        duration_days: durationDays,
        dates: dates || [],
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date ?? start_date).format("YYYY-MM-DD"),
        talent_rate: parseFloat(talent_rate),
        contract_id,
      }}
      validationSchema={BookingDateConfirmSchema}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<FormikValues>) => (
        <Form>
          <Dialog
            className="ExtendBookingDatesModal"
            header="Extend your booking"
            footer={
              <footer>
                <Button label="Confirm Extension" type="submit" icon="pi pi-check" onClick={() => props.submitForm()} />
              </footer>
            }
            visible={true}
            modal={true}
            onHide={onClose}
          >
            <p>
              Please select the start and end date of your extension and confirm the agreed day rate for the extension
            </p>

            <label htmlFor="duration_id">
              Duration Types <PopupIcon content={<DurationTypesPopup />} />
            </label>
            <ApRadioGroup
              name="duration_id"
              options={durationTypeOptions}
              onChange={({ value }) => changeDurationTypes(value, props)}
            />

            <CalendarBlock
              formik={props}
              dates={dates || []}
              minDate={moment(end_date).add(1, "d").toDate()}
              setDates={setDates}
              durationDays={durationDays}
              setDurationDays={setDurationDays}
              maxDurationDays={maxDurationDays}
              setMaxDurationDays={setMaxDurationDays}
              bookingStatus={status}
              type={ENTITY_TYPE.BOOKING}
            />

            <div className="field field-required">
              <label htmlFor="talent_rate">Agreed Day Rate</label>
              <ApInputNumber
                id="talent_rate"
                min={0}
                max={1000000}
                size={10}
                mode="currency"
                currency="GBP"
                locale="en-US"
                placeholder="Agreed Day Rate"
                useGrouping={false}
              />
            </div>
            {props.touched?.start_date && props.errors?.start_date && (
              <div className="ap-error">{props.errors?.start_date}</div>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
