import { useState } from "react";
import { ALL_DISCIPLINES } from "~/components/common/DisciplinesDropdown/DisciplinesDropdown";
import { TALENT_BRIEF_PREFERENCES, TALENT_TYPES } from "~/interfaces/brief";
import { DataTableSortMeta, DataTableSortOrderType } from "primereact/datatable";
import { ITalentFilterSettings } from "~/store/reducers/talent";
import { PaginatorPageState } from "primereact/paginator";

const defaultSort: {
  field: string;
  order: DataTableSortOrderType;
} = {
  field: "name",
  order: 1,
};

const useTalentFilterSettings = () => {
  const defaultTalentBriefPreference = TALENT_BRIEF_PREFERENCES?.find(({ code }: { code: string }) => code === ""); // empty means All
  const defaultTalentType = TALENT_TYPES?.find(({ code }: { code: string }) => code === "ALL") || {
    label: "",
    code: "",
  };
  const [perPage, setPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(1);
  const [discipline, setDiscipline] = useState(ALL_DISCIPLINES);
  const [talentBriefPreference, setTalentBriefPreference] = useState(defaultTalentBriefPreference);
  const [talentType, setTalentType] = useState(defaultTalentType);
  const [specialisms, setSpecialisms] = useState([]);
  const [levels, setLevels] = useState([]);
  const [skills, setSkills] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [tagsFilter, setTagsFilter] = useState<number[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<{ field: string; header: string }[]>([]);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<DataTableSortMeta>(defaultSort);
  const [tagsOperator, setTagsOperator] = useState<string>("OR");
  const [scrollToId, setScrollToId] = useState<number | null>(null);

  const onPaginatorChange = (e: PaginatorPageState) => {
    setPerPage(e.rows);
    setPage(e.page + 1);
  };

  const resetFilterTalentSettings = (currentPath: string) => {
    const isExistTalentId = Number.isInteger(+currentPath?.split("/")?.filter(Boolean)[1]);
    const isItemTalentPath = currentPath?.includes("/talents") && isExistTalentId;

    if (currentPath && currentPath !== "/talents" && !isItemTalentPath) {
      setDiscipline(ALL_DISCIPLINES);
      setTalentBriefPreference(defaultTalentBriefPreference);
      setTalentType(defaultTalentType);
      setSelectedColumns([]);
      setSort(defaultSort);
      setSearch("");
      setSpecialisms([]);
      setLevels([]);
      setSkills([]);
      setSectors([]);
      setTagsFilter([]);
      setTagsOperator("OR");
    }
  };

  return {
    discipline,
    defaultTalentType,
    setDiscipline,
    resetFilterTalentSettings,
    onPaginatorChange,
    talentBriefPreference,
    setTalentBriefPreference,
    defaultTalentBriefPreference,
    selectedColumns,
    setSelectedColumns,
    talentType,
    setTalentType,
    specialisms,
    setSpecialisms,
    levels,
    setLevels,
    skills,
    setSkills,
    sectors,
    setSectors,
    search,
    setSearch,
    sort,
    setSort,
    tagsFilter,
    setTagsFilter,
    tagsOperator,
    setTagsOperator,
    scrollToId,
    setScrollToId,
    page,
    perPage,
  } as ITalentFilterSettings;
};
export default useTalentFilterSettings;
