import { FormikValues } from "formik";
import isNull from "lodash/isNull";
import { BriefStatuses, IBrief } from "~/interfaces/brief";

export const getStatusOptions = (rowData: any, isAdmin: boolean, isPermanentHiringBrief: boolean = false) => {
  let colorClass: string | undefined = undefined;
  let options: any[] = [];
  let placeholder: string | undefined = undefined;

  switch (rowData.status) {
    case "PENDING":
      options = isAdmin
        ? [
            { name: "Accept brief", code: "BRIEF_ACCEPTED" },
            { name: "Decline brief", code: "BRIEF_DECLINED" },
          ]
        : [];
      colorClass = "pending";
      placeholder = "Pending";
      break;
    case "BRIEF_ACCEPTED":
      options = isPermanentHiringBrief
        ? [
            { name: "Send Job Offer", code: "OFFER_SENT" },
            { name: isAdmin ? "Request interview" : "Click to set an interview", code: "INTERVIEW_REQUESTED" },
            ...(isAdmin ? [{ name: "Decline brief", code: "BRIEF_DECLINED" }] : []),
          ]
        : [
            { name: isAdmin ? "Request booking" : "Click to request booking", code: "BOOKING_REQUESTED" },
            { name: isAdmin ? "Request interview" : "Click to set an interview", code: "INTERVIEW_REQUESTED" },
            ...(isAdmin ? [{ name: "Decline brief", code: "BRIEF_DECLINED" }] : []),
          ];
      colorClass = "accepted";
      placeholder = isPermanentHiringBrief ? "Brief accepted" : "Interested & Available";
      break;
    case "BRIEF_DECLINED":
      options = isAdmin ? [{ name: "Accept brief", code: "BRIEF_ACCEPTED" }] : [];
      colorClass = "declined";
      placeholder = "Brief declined";
      break;
    case "BOOKING_REQUESTED":
      options = isAdmin
        ? [
            { name: "Accept booking", code: "BOOKING_ACCEPTED" },
            { name: "Decline booking", code: "BOOKING_DECLINED" },
          ]
        : [];
      colorClass = "requested";
      placeholder = "Booking requested";
      break;
    case "OFFER_SENT":
      options = isAdmin
        ? [
            { name: "Job Offer Accept", code: "OFFER_ACCEPTED" },
            { name: "Job Offer Reject", code: "OFFER_REJECTED" },
          ]
        : [];
      colorClass = "requested";
      placeholder = "Offer Sent";
      break;
    case "INTERVIEW_REQUESTED":
      options = isAdmin ? [{ name: "Accept brief", code: "BRIEF_ACCEPTED" }] : [];
      colorClass = "pending";
      placeholder = "Interview requested";
      break;
    case "INTERVIEW_ACCEPTED":
      options = isPermanentHiringBrief
        ? [
            ...(isAdmin ? [{ name: "Job Offer Accept", code: "OFFER_ACCEPTED" }] : []),
            ...(isAdmin ? [{ name: "Job Offer Reject", code: "OFFER_REJECTED" }] : []),
            { name: "Send Job Offer", code: "OFFER_SENT" },
          ]
        : isAdmin
        ? [
            { name: "Request booking", code: "BOOKING_REQUESTED" },
            { name: "Decline brief", code: "BRIEF_DECLINED" },
          ]
        : [{ name: "Click to request booking", code: "BOOKING_REQUESTED" }];
      colorClass = "accepted";
      placeholder = "Interview requested";
      break;
    case "AWAITING_AVAILABILITY":
      options = isAdmin
        ? [
            { name: "Accept brief", code: "BRIEF_ACCEPTED" },
            { name: "Decline brief", code: "BRIEF_DECLINED" },
          ]
        : [];
      colorClass = "requested";
      placeholder = "Awaiting availability";
      break;
    case "BOOKING_ACCEPTED":
      colorClass = "accepted";
      placeholder = "Booking accepted";
      break;
    case "BOOKING_DECLINED":
      colorClass = "declined";
      placeholder = "Booking declined";
      break;
    case "BOOKING_EXTENSION_REQUESTED":
      colorClass = "pending";
      placeholder = "Extension requested";
      break;
    case "BOOKING_EXTENDED":
      colorClass = "accepted";
      placeholder = "Booking extended";
      break;
    case "BOOKING_AMENDMENT_REQUESTED":
      colorClass = "pending";
      placeholder = "Amendment requested";
      break;
    case "BOOKING_AMENDED":
      colorClass = "accepted";
      placeholder = "Booking amended";
      break;
    case "OFFER_ACCEPTED":
      colorClass = "accepted";
      placeholder = "Offer accepted";
      break;
    case "OFFER_REJECTED":
      colorClass = "declined";
      placeholder = "Offer rejected";
      break;
    case "REMOVED":
      colorClass = "declined";
      placeholder = "Talent removed";
      break;
    case "BRIEF_CANCELLED":
      colorClass = "declined";
      placeholder = "Brief cancelled";
      break;
    case "FULLY_CONFIRMED":
      colorClass = "accepted";
      placeholder = "Requested";
      break;
    case "PENDING":
      colorClass = "pending";
      placeholder = "Pending";
      break;
    default:
      options = [];
      break;
  }
  return {
    colorClass,
    options,
    placeholder,
  };
};
export const getSuccessMessageForBriefMatching = (requestedMatches: number, matchesFound: number) => {
  let msg;
  let severity = "success";

  if (matchesFound === 0) {
    severity = "error";
    msg = "No matches to add";
  } else if (matchesFound < requestedMatches) {
    msg = `${matchesFound} match${matchesFound > 1 ? "es" : ""} added. No more matches`;
  } else {
    msg = "Matches have been added successfully";
  }
  return {
    msg,
    severity,
  };
};

export const checkBriefForPublished = (brief: IBrief) => brief.status === BriefStatuses?.PUBLISHED;
export const checkBriefForUnpublished = (brief: IBrief) => brief.status === BriefStatuses?.UNPUBLISHED;
export const checkBriefForDraft = (brief: IBrief) => brief.status === BriefStatuses?.DRAFT;
export const checkBriefForPermanent = (
  formikValues: FormikValues | IBrief | undefined,
  CONTRACT_TYPES: { [key: string]: number }
) => formikValues?.contract_id === CONTRACT_TYPES?.PERMANENT;

export const draftBriefValues = (brief: IBrief) => {
  const allEntries = (typeOfEntries: string) => {
    const customEntries = `custom_${typeOfEntries}`;
    const mergedEntries = [...brief[typeOfEntries], ...brief[customEntries].map((el) => ({ name: el.name }))];
    return mergedEntries
      .sort((a: any, b: any) => (a.id && !b.id ? -1 : !a.id && b.id ? 1 : 0))
      .map((entry: any, key: number) => ({
        ...entry,
        order_id: key + 1,
      }));
  };

  const briefFormated = {
    ...brief,
    ...brief.ir35,
    id: brief.id,
    skills: allEntries("skills"),
    sectors: allEntries("sectors"),
    platforms: allEntries("platforms"),
    excluded_talents: !!brief.excluded_talents.length ? brief.excluded_talents.map((talent) => talent.id) : [],
    duration_days: brief.duration_days + brief.duration_weekends,
  };
  delete briefFormated.ir35;
  delete briefFormated.ir35_type;
  delete briefFormated.custom_skills;
  delete briefFormated.custom_sectors;
  delete briefFormated.custom_platforms;
  delete briefFormated.author;
  delete briefFormated.client;
  delete briefFormated.matches;
  delete briefFormated.umbrella_companies;
  delete briefFormated.region;
  delete briefFormated.first_response;
  if (briefFormated.contract_id !== 1) {
    briefFormated.ir35_id = null;
  }

  return briefFormated;
};

export const formatValuesBeforeBriefSaving = (
  form: any,
  formValues: any,
  params: {
    LOCATION_FLEXIBLE_CODE: number;
    CONTRACT_TYPES: { [key: string]: number };
    DURATION_ID: { [key: string]: number };
    IR35_TYPES: { [key: string]: number };
  }
) => {
  const { LOCATION_FLEXIBLE_CODE, CONTRACT_TYPES, IR35_TYPES, DURATION_ID } = params;
  const values = { ...formValues };
  const isDraft = checkBriefForDraft(formValues);
  const isPermanentHiringBrief = values.contract_id === CONTRACT_TYPES?.PERMANENT;

  if (values.languages) {
    values.languages = values.languages.map(({ id }: { id: number }) => id);
  }

  if (form.ir35_id === IR35_TYPES.INSIDE) {
    delete values.freelancer_arrange_space;
    delete values.freelancer_defines_location;
  } else if (form.ir35_id === IR35_TYPES.OUTSIDE && values.freelancer_defines_location === "NO") {
    delete values.freelancer_arrange_space;
  }

  if (form.ir35_id === IR35_TYPES.OUTSIDE && values.freelancer_defines_location !== "NO") {
    values.location_id = LOCATION_FLEXIBLE_CODE;
  }

  if (!values.location_id) {
    delete values.location_id;
  }

  if (values.production_size === "") {
    delete values.production_size;
  }

  if (!values.team_id) {
    values.team_id = 3; // FLEXIBLE
  }
  if (isDraft) {
    ["freelancer_control", "freelancer_substitute", "freelancer_other_projects", "freelancer_amend"].forEach((el) =>
      !values[el] ? (values[el] = null) : (el = el)
    );
  }
  if (isPermanentHiringBrief) {
    ["dates", "start_date", "end_date", "dates_flexible"].forEach((el) => (values[el] = null));
    values.duration_id = DURATION_ID.PERMANENT;
    values.include_weekends = false;
  }
  if (
    form.exempt ||
    form.umbrella ||
    form.ir35_compliant ||
    form.ir35_id === IR35_TYPES.UMBRELLA ||
    form.contract_id === CONTRACT_TYPES.FIXEDTERM ||
    isPermanentHiringBrief
  ) {
    values.ir35_id = isPermanentHiringBrief
      ? IR35_TYPES.PERMANENT
      : form.ir35_id ||
        (form.umbrella || form.ir35_id === IR35_TYPES.UMBRELLA ? IR35_TYPES.UMBRELLA : IR35_TYPES.EXEMPT);
    delete values.exempt;
    delete values.freelancer_amend;
    delete values.freelancer_control;
    delete values.freelancer_substitute;
    delete values.freelancer_credits;
    delete values.freelancer_equipment;
    delete values.freelancer_flexibility;
    delete values.freelancer_introducing;
    delete values.freelancer_managerial;
    delete values.freelancer_other_projects;
    delete values.freelancer_staff;

    if (!isPermanentHiringBrief) {
      values.freelancer_defines_location = form.freelancer_defines_location === "YES" ? "YES" : "NO";
      values.freelancer_arrange_space = form.freelancer_arrange_space === "YES" ? "YES" : "NO";
    }
  }

  if (form.contract_id === CONTRACT_TYPES.FIXEDTERM) {
    values.ir35_id = IR35_TYPES.FIXEDTERM;
  }
  if (form.contract_id === CONTRACT_TYPES.FREELANCE) {
    if (form.umbrella) {
      values.ir35_id = IR35_TYPES.UMBRELLA;
    }
    if (form.exempt) {
      values.ir35_id = IR35_TYPES.EXEMPT;
    }
  }
  if (values.budget === "") {
    values.budget = null;
    values.budget_type = null;
  } else if (!isNull(values.budget)) {
    values.budget = +values.budget;
  }

  if (isDraft) {
    delete values.skill_list;
    delete values.platform_list;
    delete values.sector_list;
    if (!values.production_id) {
      delete values.production_id;
    }
    if (!values.start_date) {
      delete values.start_date;
      delete values.end_date;
      delete values.dates;
      delete values.duration_id;
    }
  }
  return values;
};
