.ImageCropContainer {
  .p-button {
    margin-top: 10px;
    justify-content: center;
  }
  input[type="file"] {
    display: none;
  }
  label {
    font-size: 14px;
    font-weight: 700;
  }
}
